@font-face {
    font-family: allFont;
    src: url(../public/Montserrat-VariableFont_wght.ttf);
}

body {
    margin: 0;
    font-family: allFont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: judul;
    src: url(../public/OpenSans-ExtraBold.ttf);
}

.pointer:hover{
    cursor: pointer;
}

.gallery{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999 !important;
    background: rgba(0, 0, 0, .8);
    text-align: center;
}

.hidden{
    display: none;
    width: 0;
    height: 0;
    overflow: hidden;
    transition: 0.8s;
}

.gallery img{
    max-height: 100%;
    max-width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom-btn {
    background-color:#f46b0c;
    text-transform: uppercase;
  }