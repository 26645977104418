.nav-item.dropdown a{
    color: #fff;
    font-weight: bold;
}

.dropdown-menu.show a{
    color: #1C1C1C;
}

a.dropdown-toggle.show.nav-link{
    color: #fff;
    font-weight: bold;
}