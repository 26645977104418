.footer{
    background-color: #f86c0c;
    color: #f8f4f4;
  }
  
.footer a{
    color: #f8f4f4;
}

.textOrange{
    color: #f8f4f4;
}

.copyRight{
    background-color: #d0cccc;
    color: #ff8c00;
  }